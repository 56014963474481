import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { EMOTION, HEADER } from '../../constant';

function Exit(props) {
  const details = props.getDetail();
  const total = details.map(d => d[1]).reduce((acc, cur) => cur + acc, 0);
  let percent = 0;
  if (total >= 69) percent = 99;
  else if (total > 0)
    percent = Math.floor(Math.random() * (70 - total)) + total + 30;
  return (
    <div className={props.className}>
      <div className="exit-emotions">
        {Object.entries(EMOTION).map(([property, imgSrc]) => (
          <img
            key={property}
            src={imgSrc}
            alt={property}
            className="exit-emotion"
          />
        ))}
      </div>
      <div className="greet">上班順利！祝美好的一天</div>
      <div className="total-read">
        <div>你今天的閱讀量</div>
        <div>
          <strong>高於{percent}%用戶</strong>
        </div>
      </div>
      <div className="detail">
        {details.map(d => (
          <div key={d[0]} className="detail-line">
            <span>{d[0]}</span>
            <span>{d[1]} 則</span>
          </div>
        ))}
      </div>
      <div className="exit-buttons">
        <button className="exit-button left" onClick={props.goBack}>
          繼續瀏覽
        </button>
        <button className="exit-button" onClick={props.goBack}>
          離開
        </button>
      </div>
    </div>
  );
}

export default styled(Exit)`
  animation: fade-in 2s;
  height: 100%;
  padding: 30px;
  padding-top: ${HEADER.HEIGHT + 30}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  /* z-index: 1; */
  background-color: #f6f4f7;
  .exit-emotions {
    height: 120px;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .exit-emotion {
    position: absolute;
    width: 50px;
    height: 50px;
  }
  .exit-emotion:nth-child(1) {
    transform: translate(-85px, 5px) scale(0.9);
  }
  .exit-emotion:nth-child(2) {
    transform: translate(-30px, -5px) scale(0.9);
  }
  .exit-emotion:nth-child(3) {
    transform: translate(30px, -2px) scale(1.2);
  }
  .exit-emotion:nth-child(4) {
    transform: translate(85px, 10px) scale(0.9);
  }
  .greet {
    line-height: 3;
    color: #4215a1;
  }
  .total-read {
    width: 100%;
    padding: 30px;
    color: #fff;
    border-radius: 1px;
    box-shadow: 0 0 12px 0 rgba(41, 35, 50, 0.67);
    background: linear-gradient(253deg, #48367b, #bf83dd);
    strong {
      font-size: 25px;
    }
  }
  .detail {
    width: 100%;
    color: #4215a1;
    margin-top: 15px;
  }
  .detail-line {
    border-radius: 1px;
    display: flex;
    background: #fff;
    padding: 0 25px;
    align-items: center;
    justify-content: space-between;
    height: 45px;
    font-weight: 300px;
    margin-top: 5px;
  }
  .exit-buttons {
    margin-top: auto;
    height: 45px;
    font-size: 15px;
    display: flex;
    justify-content: center;
  }
  .exit-button {
    border: 0;
    border-radius: 22.5px;
    background-image: linear-gradient(78deg, #bb7fd9, #312668);
    height: 45px;
    width: 150px;
    font-size: 15px;
    color: #fff;
    &.left {
      margin-right: 10px;
      border: solid 1px #4215a1;
      color: #4215a1;
      background: transparent;
    }
  }

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
