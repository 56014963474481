import ReactDOM from 'react-dom';
import React from 'react';
import './style.scss';
import App from './App';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }
  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo });
  }
  render() {
    if (this.state.error) {
      alert(this.state.error);
      return <div>{this.state.errorInfo}</div>;
    } else {
      //when there's not an error, render children untouched
      return this.props.children;
    }
  }
}

// ReactDOM.render(
//   <ErrorBoundary>
//     <App />
//   </ErrorBoundary>,
//   document.querySelector('#root'),
// );

ReactDOM.render(<App />, document.querySelector('#root'));
