import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import genIDer from '../../helpers/genID';
import { EMOTION } from '../../constant';
import mojs from '@mojs/core';
const genID = genIDer();
const SIZE = 30;

function Emotions({ className, emotions = [] }) {
  return (
    <div className={className}>
      {emotions.map(emo => (
        <Emotion key={emo.id} imgSrc={emo.imgSrc} delay={emo.delay} />
      ))}
    </div>
  );
}

function Emotion({ imgSrc, delay = 10 }) {
  const ref = useRef(null);
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setMounted(true);
    }, delay);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  useEffect(() => {
    const target = ref.current;
    if (!target || !mounted) return;
    const coords = {
      x: Math.random() * (window.innerWidth - 110) + 30,
      y: Math.random() * 250 + 400,
    };
    const html = new mojs.Html({
      el: target,
      opacity: {
        1: 0,
        delay: 500,
        duration: 500,
      },
      x: {
        [coords.x]: coords.x,
      },
      y: {
        [coords.y]: coords.y - 15,
        duration: 500,
        easing: 'cubic.out',
        onComplete() {
          // console.log(`${id} complete`);
        },
      },
    });

    const RADIUS = 28;
    const circle = new mojs.Shape({
      left: 29,
      top: -83,
      stroke: '#FF9C00',
      strokeWidth: { [2 * RADIUS]: 0 },
      fill: 'none',
      scale: { 0: 1.2, easing: 'quad.out' },
      radius: RADIUS,
      duration: 450,
      delay: 500,
      onComplete: function() {
        this.el.remove();
      },
    });

    const burst = new mojs.Burst({
      left: 30,
      top: -83,
      radius: { 6: RADIUS },
      angle: 45,
      children: {
        shape: 'line',
        radius: RADIUS / 7.3,
        scale: 1.2,
        stroke: '#FD7932',
        strokeDasharray: '100%',
        strokeDashoffset: { '-100%': '100%' },
        degreeShift: 'stagger(0,-5)',
        duration: 700,
        delay: 700,
        easing: 'quad.out',
      },
      onComplete: function() {
        this.el.remove();
      },
    });
    const timeline = new mojs.Timeline({ speed: 1.5 });
    burst.tune(coords);
    circle.tune(coords);
    timeline.add(burst, circle);
    html.play();
    timeline.play();
  }, [mounted]);
  const size = 60;
  return (
    mounted && (
      <div
        ref={ref}
        className="emotion"
        style={{
          height: size,
          width: size,
        }}
      >
        <img className="emotion-img" src={imgSrc} alt="" />
      </div>
    )
  );
}
// emo { id: Int, imgSrc: String, delay: Int(ms) }
export function useEmotions(max = 10) {
  const [emotions, setEmotions] = useState([]);
  function addEmotion(imgSrc) {
    setEmotions(emos => [...emos, { id: genID(), imgSrc }].slice(-max));
  }
  // [imgSrc: String, amount: Int]
  function _setEmotions(emos) {
    const amount = emos[1] < max ? emos[1] : max;
    setEmotions(
      Array.from({ length: amount }, (_, i) => ({
        imgSrc: emos[0],
        id: genID(),
        delay: i * 50,
      })),
    );
  }
  return [emotions, addEmotion, _setEmotions];
}

export default styled(Emotions)`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  .emotion {
    filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.3));
    position: absolute;
    top: -100px;
    width: 100px;
    height: 100px;
  }
  .emotion-img {
    object-fit: contain;
    width: 100%;
    height: 100%;
    /* transform: translate(-100%, -100%); */
  }
`;
