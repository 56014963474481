import { EMOTION } from '../constant';

export default function(data) {
  const emos = [];
  const emosCount = [];
  Object.entries(EMOTION).forEach(([emoKey, imgSrc]) => {
    emos.push(imgSrc);
    emosCount.push(data[emoKey]);
  });
  let maxIndex = 0;
  for (let i = 0; i < 3; i++) {
    if (emosCount[i] < emosCount[i + 1]) maxIndex = i + 1;
  }

  return [emos[maxIndex], emosCount[maxIndex]];
}
