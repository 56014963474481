import { useState, useRef, useEffect } from 'react';

const useGetterState = initState => {
  const [state, setState] = useState(initState);
  const ref = useRef(state);
  useEffect(() => {
    ref.current = state;
  }, [state]);
  return [state, setState, () => ref.current];
};

export default useGetterState;
