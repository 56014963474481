import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  forwardRef,
} from 'react';
import styled from 'styled-components';
import {
  styler as S,
  listen,
  pointer,
  value as V,
  tween,
  physics,
  spring,
} from 'popmotion';
import { DECK } from '../../constant';
import posed from 'react-pose';

function Card({
  children,
  className,
  cover,
  cid,
  onRemove,
  onSwipe,
  active,
  index,
  rotate,
  height,
  width,
  pose,
  title,
  isFavorite,
}) {
  const ref = useRef(null);
  const _onRemove = useCallback(() => onRemove(cid), [cid]);
  const _onSwipe = () => {
    onSwipe(cid);
  };
  const [mounted, setMounted] = useState(true);
  const startSwipe = useSwipe(ref, {
    active: active,
    onRemove: _onRemove,
    onSwipe: _onSwipe,
  });
  const [deg, setDeg] = useState(0);
  useEffect(() => {
    if ((deg % 360 === 0 && cover) || (deg % 360 === 180 && !cover)) {
      setDeg(deg + 180);
    }
  }, [cover, deg]);
  const [covered, setCovered] = useState(false);
  function onTransitionEnd(e) {
    if (e.propertyName === 'transform') {
      setCovered(true);
    }
  }
  return (
    <div
      ref={ref}
      className={className}
      style={{
        height,
        width,
        /* iOS safari rotateY, rotate bug */
        position: active && pose === 'card' ? 'fixed' : 'absolute',
      }}
    >
      <div
        style={{
          height: '100%',
        }}
      >
        {pose === 'deck' && (
          <DeckHeader index={index}>
            <div className="deck-header-content">
              <div className="deck-header-title">{title}</div>
              <div className="deck-header-favorite">
                {isFavorite ? '⭐' : ''}
              </div>
            </div>
          </DeckHeader>
        )}
        <CC
          i={index}
          pose={rotate}
          className="transformer"
          poseKey={index}
          style={{ height: '100%', width: '100%' }}
        >
          <div
            className="front"
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              transition: '.5s',
              backfaceVisibility: 'hidden',
              WebkitBackfaceVisibility: 'hidden',
              opacity: 0.9999,
              transform: `rotateY(${deg}deg)`,
            }}
            onTransitionEnd={onTransitionEnd}
          >
            {children.length > 1
              ? children[0](startSwipe, mounted)
              : children(startSwipe, mounted)}
          </div>
          <div
            className="back"
            style={{
              pointerEvents: cover ? 'auto' : 'none',
              width: '100%',
              height: '100%',
              backfaceVisibility: 'hidden',
              position: 'absolute',
              transition: '.5s',
              // safari
              WebkitBackfaceVisibility: 'hidden',
              // chrome backface-visibility
              opacity: 0.9999,
              transform: `rotateY(${deg + 180}deg)`,
            }}
          >
            {children.length > 1 ? children[1](startSwipe, covered) : null}
          </div>
        </CC>
      </div>
    </div>
  );
}
function useSwipe(target, { active, onRemove, onSwipe, setValue }) {
  const downHandler = useRef(() => {});
  useEffect(() => {
    const card = target.current;
    if (!card || !active) return;
    const cardStyler = S(card);
    const cardXY = V({ x: 0, y: 0 }, ({ x }) => setSwipeStyle(x));
    function setSwipeStyle(v) {
      cardStyler.set({ x: v, rotate: v / 20 });
    }
    let pointerTracker;
    let backAnimation;
    let goAnimation;
    let timer;
    downHandler.current = () => {
      if (backAnimation) backAnimation.stop();
      pointerTracker = pointer(cardXY.get()).start(cardXY);
    };
    const upListener = listen(document, 'touchend').start(() => {
      if (pointerTracker) pointerTracker.stop();
      const thresholdX = 600;
      const { x: vx } = cardXY.getVelocity();
      const { x } = cardXY.get();
      if ((vx >= thresholdX && x >= 0) || (vx <= -thresholdX && x <= 0)) {
        upListener.stop();
        goAnimation = physics({
          from: cardXY.get(),
          velocity: vx,
        }).start(cardXY);
        timer = setTimeout(() => {
          onSwipe();
          goAnimation.stop();
          goAnimation = tween({
            from: cardXY.get(),
            to: 0,
          }).start({
            update: v => cardXY.update(v),
            complete: () => onRemove(),
          });
        }, 300);
      } else {
        backAnimation = spring({
          from: cardXY.get(),
          stiffness: 150,
          damping: 18,
        }).start(cardXY);
      }
    });
    return () => {
      if (pointerTracker) pointerTracker.stop();
      if (upListener) upListener.stop();
      if (backAnimation) backAnimation.stop();
      downHandler.current = () => {};
      clearTimeout(timer);
    };
  }, [active]);
  return e => downHandler.current(e);
}
const CC = posed.div({
  'tilt-2': {
    rotate: -2,
    rotateX: 0,
    originY: '50%',
    y: 8,
    transition: {
      type: 'tween',
      duration: 400,
    },
  },
  normal: {
    rotate: 0,
    rotateX: 0,
    originY: '50%',
    y: 0,
    transition: {
      type: 'tween',
      duration: 400,
    },
  },
  '3d': {
    rotate: 0,
    rotateX: -40,
    originY: '0%',
    y: ({ i }) => i * DECK.CARD_MARGIN,
    transition: {
      type: 'tween',
      duration: 400,
    },
  },
});
const DeckHeader = styled.div.attrs(props => ({
  style: {
    transform: `translateY(${props.index * DECK.CARD_MARGIN}px)`,
  },
}))`
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-radius: 3px;
  border-top: 26px solid #755388;
  transition: opacity 1s 0.5s;
  .deck-header-content {
    position: relative;
    width: 100%;
    padding: 5px 10px;
    top: -26px;
    height: 26px;
    line-height: 26px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 13px;
    color: #fff;
  }
  .deck-header-title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    flex: 1;
  }
  .deck-header-favorite {
    padding-left: 10px;
  }
  .deck-header-star {
    width: 26px;
    height: 26px;
  }
`;
export default Card;
