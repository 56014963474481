import _0 from './0.json';
import _1 from './1.json';
import _2 from './2.json';
import _3 from './3.json';
import _4 from './4.json';
import _5 from './5.json';
import apiObject from './apiObject.json';
import trending from './trending.json';

export default {
  0: _0,
  1: _1,
  2: _2,
  3: _3,
  4: _4,
  5: _5,
  trending,
  apiObject,
};
