import React, { useState, useEffect, useRef } from 'react';
import {
  styler as S,
  listen,
  pointer,
  value as V,
  tween,
  decay,
} from 'popmotion';
import styled from 'styled-components';
import { FOOTER, HEADER, DECK } from '../../constant';
import Card from '../../components/Card';
import facebook from '../../icons/facebook.png';
import youtube from '../../icons/youtube.png';
import twitter from '../../icons/twitter.png';
import instagram from '../../icons/instagram.png';
import star from '../../icons/star.png';
import starEmpty from '../../icons/star-empty.png';

const socialIcons = {
  facebook,
  youtube,
  twitter,
  instagram,
};

function Cards({
  className,
  user,
  cards,
  setCards,
  style,
  setStyle,
  addFavorite,
  removeFavorite,
  apisObject,
  chooseApis,
  cardsPose,
}) {
  const CARD_HEIGHT = useCardHeight();
  const CARD_WIDTH = useCardWidth();
  function onRemove(id) {
    setCards(cards => {
      const newCards = [
        ...cards.map(card =>
          card.id == id ? { ...card, active: false } : card,
        ),
      ];
      return newCards;
    });
  }
  // when you swipe out all cards too fast
  useEffect(() => {
    if (!cards.filter(c => c.active).length) {
      setCards(
        cards.map((c, i) =>
          i === cards.length - 1 ? { ...c, active: true } : c,
        ),
      );
    }
  }, [cards.filter(c => c.active).length]);
  function onSwipe(id) {
    setCards(cards => {
      let swipeIndex = cards.findIndex(card => card.id == id);
      let index;
      if (swipeIndex === 0) {
        index = cards.length - 1;
      } else {
        index = swipeIndex - 1;
      }
      return [
        { ...cards[swipeIndex], cover: false },
        ...cards
          .filter(card => card.id != id)
          .map((card, i) => (i == index ? { ...card, active: true } : card)),
      ];
    });
  }
  function onClickKG(id, kg) {
    setCards(
      cards.map(card =>
        card.id == id
          ? { ...card, cover: !card.cover, currentKG: kg || card.currentKG }
          : card,
      ),
    );
  }
  function changeStyle() {
    setStyle(style === 'deck' ? 'card' : 'deck');
  }
  function getRotate(index) {
    if (style === 'deck') return '3d';
    if (index === cards.length - 2) return 'tilt-2';
    return 'normal';
  }
  function chooseCard(index) {
    setCards(cards => {
      return [...cards.slice(index + 1), ...cards.slice(0, index + 1)].map(
        (c, i) =>
          i === cards.length - 1
            ? c
            : {
                ...c,
                cover: false,
                active: false,
              },
      );
    });
    setStyle('card');
  }
  const cardsContainer = useRef(null);
  useEffect(() => {
    const target = cardsContainer.current;
    if (!target || style === 'card') return;
    let pointerTracker;
    let downListener;
    let upListener;
    let clickListener;
    const cardsStyler = S(target);
    cardsStyler.set('height', CARD_HEIGHT + DECK.CARD_MARGIN * cards.length);
    const cardsY = V(0, v => cardsStyler.set('y', v));
    clickListener = listen(target, 'click').start(e => {
      const y = -cardsY.get();
      const offsetY = e.pageY - 80 + y;
      let index = Math.floor(offsetY / DECK.CARD_MARGIN);
      if (index >= cards.length) index = cards.length - 1;
      chooseCard(index);
    });
    downListener = listen(target, 'touchstart', true).start(e => {
      e.stopPropagation();
      pointerTracker = pointer({ x: 0, y: cardsY.get() })
        .pipe(({ y }) => y)
        .start(cardsY);
    });
    upListener = listen(document, 'touchend').start(() => {
      if (pointerTracker) pointerTracker.stop();
      decay({
        from: cardsY.get(),
        velocity: cardsY.getVelocity(),
      })
        .pipe(
          v => Math.max(-(cards.length - 1) * DECK.CARD_MARGIN, v),
          v => Math.min(0, v),
        )
        .start(cardsY);
    });
    return () => {
      setTimeout(() => {
        cardsStyler.set('height', CARD_HEIGHT);
      }, 0);
      tween({ from: cardsY.get(), to: 0 }).start(cardsY);
      if (clickListener) clickListener.stop();
      if (pointerTracker) pointerTracker.stop();
      if (upListener) upListener.stop();
      if (downListener) downListener.stop();
    };
  }, [cards.length, style, CARD_HEIGHT]);
  return (
    <div
      className={className}
      style={{ animation: `0.3s ${cardsPose} forwards` }}
    >
      <div ref={cardsContainer} className="cards">
        {cards.map((card, index) => {
          const {
            id,
            cover,
            thumbnails,
            summary,
            entity_set,
            url,
            active,
            currentKG,
            entity_info,
            title,
            read_time,
            originIndex,
            provider,
            pubdate,
          } = card;
          const isFavorite = user.news && user.news.map(n => n.id).includes(id);
          if (id === 'choose') {
            return (
              <Card
                height={CARD_HEIGHT}
                width={CARD_WIDTH}
                active={active}
                className="card-outer"
                onRemove={onRemove}
                onSwipe={onSwipe}
                cid={id}
                key={id}
                cover={false}
                index={index}
                rotate={getRotate(index)}
                pose={style}
              >
                {onTouchStart => (
                  <div className="card-inner card-front">
                    <StyledChoose
                      onTouchStart={onTouchStart}
                      apisObject={apisObject}
                      onConfirm={chooseApis}
                    />
                  </div>
                )}
              </Card>
            );
          }
          return (
            <Card
              height={CARD_HEIGHT}
              width={CARD_WIDTH}
              active={active}
              className="card-outer"
              onRemove={onRemove}
              onSwipe={onSwipe}
              cid={id}
              key={id}
              cover={cover}
              index={index}
              rotate={getRotate(index)}
              pose={style}
              title={title}
              isFavorite={isFavorite}
            >
              {(onTouchStart, mounted) => (
                <div className="card-inner card-front">
                  <div className="open-graph">
                    <div className="index">
                      {cards.length - originIndex}/{cards.length}
                    </div>
                    <img
                      src={
                        (mounted && thumbnails) ||
                        'https://via.placeholder.com/360'
                      }
                      alt=""
                    />
                    <a
                      href={url}
                      target="_blank"
                      className="open-graph-text"
                      style={{
                        transform: style === 'card' ? '' : `translateY(100px)`,
                      }}
                    >
                      {title}
                    </a>
                  </div>
                  <div className="content" onTouchStart={onTouchStart}>
                    <div className="content-title">
                      <div className="left">摘要自 {provider}</div>
                      <div className="right">{pubdate}</div>
                    </div>
                    <div className="content-text-container">
                      <div className="content-text">
                        {highLight(summary, entity_set, kg =>
                          onClickKG(id, kg),
                        )}
                      </div>
                    </div>
                    <div className="content-footer">
                      <a className="left" href={url} target="_blank">
                        <div className="icon" />
                        <div className="estimate">{read_time} min read</div>
                      </a>
                      {isFavorite ? (
                        <div
                          className="right"
                          onClick={() => {
                            removeFavorite(card);
                          }}
                        >
                          <img src={star} alt="favorite" />
                        </div>
                      ) : (
                        <div
                          className="right"
                          onClick={() => {
                            addFavorite(card);
                          }}
                        >
                          <img src={starEmpty} alt="favorite" />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {(onTouchStart, covered) => {
                const kg = entity_info && entity_info[currentKG];
                return (
                  kg && (
                    <div
                      className="card-inner card-back"
                      onClick={() => onClickKG(id)}
                    >
                      <div className="header">
                        <div className="kg-title">
                          <div className="kg-title-cn">{currentKG}</div>
                          <div className="kg-title-en">{kg.en_name}</div>
                        </div>
                        <div className="kg-img">
                          {covered && <img src={kg.img} alt="kg-img" />}
                        </div>
                      </div>
                      <div className="content">
                        <div className="description">{kg.description}</div>
                        <div className="properties">
                          {kg.property &&
                            Object.entries(kg.property)
                              .slice(0, 3)
                              .map(([p, v]) => (
                                <div
                                  key={p}
                                  className="property"
                                >{`${p}：${v}`}</div>
                              ))}
                        </div>
                        <div className="socials">
                          {kg.social_media_profiles &&
                            Object.entries(kg.social_media_profiles).map(
                              ([p, v]) => (
                                <a
                                  key={p}
                                  href={v}
                                  target="_blank"
                                  onClick={e => e.stopPropagation()}
                                >
                                  <div className="social">
                                    <div className="social-img">
                                      {covered && (
                                        <img src={socialIcons[p]} alt={p} />
                                      )}
                                    </div>
                                    <div className="social-title">{p}</div>
                                  </div>
                                </a>
                              ),
                            )}
                        </div>
                        <div className="relatives-container">
                          <div className="relatives-title">相關新聞/影音</div>
                          <div className="relatives">
                            {kg.related_news.slice(0, 3).map(news => (
                              <a
                                key={news.url}
                                href={news.url}
                                target="_blank"
                                className="relative"
                                onClick={e => e.stopPropagation()}
                              >
                                {news.title}
                              </a>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                );
              }}
            </Card>
          );
        })}
      </div>
    </div>
  );
}
const highLight = (text, patterns, onClick) => {
  const regex = new RegExp(patterns.join('|'), 'g');
  const splitText = text.split(regex);
  if (splitText.length <= 1) {
    return text;
  }
  const matches = text.match(regex);
  return splitText.reduce(
    (arr, element, index) =>
      matches[index]
        ? [
            ...arr,
            element,
            <mark onClick={() => onClick(matches[index])} key={index}>
              {matches[index]}
            </mark>,
          ]
        : [...arr, element],
    [],
  );
};

function useCardWidth() {
  const [width, setWidth] = useState(window.innerWidth - 44);
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth - 44);
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return width;
}

function useCardHeight() {
  // 48: 24px for top, bottom padding
  const [height, setHeight] = useState(
    window.innerHeight - 48 - FOOTER.HEIGHT - HEADER.HEIGHT,
  );
  useEffect(() => {
    // iOS Safari height bug
    const timer = setTimeout(handleResize, 1000);
    function handleResize() {
      setHeight(window.innerHeight - 48 - FOOTER.HEIGHT - HEADER.HEIGHT);
    }
    window.addEventListener('resize', handleResize);
    return () => {
      clearTimeout(timer);
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return height;
}

const Choose = ({ onTouchStart, className, apisObject, onConfirm, index }) => {
  const [apis, setApis] = useState([]);
  function toggle(api) {
    setApis(apis.map(a => (a.api === api ? { ...a, active: !a.active } : a)));
  }
  useEffect(() => {
    setApis(
      Object.entries(apisObject)
        .slice(0, 6)
        .map(([api, name]) => ({ api, name, active: false })),
    );
  }, [apisObject]);
  function confirm() {
    const activeApis = apis.filter(a => a.active);
    if (activeApis.length === 0) return;
    onConfirm(activeApis);
  }
  return (
    <div className={className} onTouchStart={onTouchStart}>
      <div className="choose-content">
        <div className="choose-qk">Hi！休息一下繼續看</div>
        <div className="choose-question">你還對哪些主題有興趣呢</div>
        <div className="choose-buttons">
          {apis.map(({ name, api, active }) => (
            <button
              key={api}
              onClick={() => toggle(api)}
              className={`choose-button ${
                active ? 'choose-button-active' : ''
              }`}
            >
              {name}
            </button>
          ))}
        </div>
        <button
          onClick={confirm}
          className="confirm"
          style={{
            backgroundColor: apis.filter(a => a.active).length
              ? '#fff'
              : '#c8c8c8',
          }}
        >
          確定
        </button>
      </div>
    </div>
  );
};

const StyledChoose = styled(Choose)`
  box-shadow: 0 0 12px 0 rgba(41, 35, 50, 0.67);
  background-image: linear-gradient(216deg, #48367b, #bf83dd);
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  color: #fff;
  .index {
    position: absolute;
    right: 15px;
    top: 12px;
    font-size: 12px;
    padding: 1px 12px;
    background: rgba(216, 216, 216, 0.5);
    border-radius: 10px;
    color: #fff;
  }
  .choose-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 45px 40px 40px;
    height: 100%;
  }
  .choose-qk {
    font-size: 14px;
  }
  .choose-question {
    line-height: 40px;
  }
  .choose-buttons {
    flex: 1;
    width: 100%;
    margin-top: 20px;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5px;
  }
  .choose-button {
    font-size: 16px;
    color: #fff;
    font-weight: 300;
    border-radius: 12px;
    border: solid 1px rgba(255, 255, 255, 0.5);
    background: transparent;
    transition: 0.3s;
    outline: none;
  }
  .choose-button-active {
    font-weight: 500;
    border: solid 1px #ffec7c;
    background: #ffec7c;
    color: #523d84;
  }
  .confirm {
    border: 0;
    font-size: 16px;
    height: 45px;
    width: 150px;
    border-radius: 22.5px;
    margin-top: 20px;
    background: #fff;
    color: #5e448d;
    transition: 0.3s;
  }
`;

export default styled(Cards)`
  @keyframes in {
    from {
      transform: translateX(500px);
    }
    to {
      transform: translateX(0);
    }
  }
  @keyframes out {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-500px);
    }
  }
  /* @keyframes over {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  } */

  height: 100%;
  width: 100%;
  position: absolute;
  perspective: 1100;
  .cards {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    transform-style: preserve-3d;
    flex-direction: column;
    width: 100%;
    margin-top: ${HEADER.HEIGHT + 24}px;
  }
  .card-outer {
    user-select: none;
    &:nth-last-child(-n + 2) .transformer {
      box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.3);
    }
    /* prevent events of active card */
    &:not(:last-child) {
      pointer-events: none;
    }
  }
  .card-inner {
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-radius: 2px;
    /* Safari weird overflow bug */
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    background: white;
    display: flex;
    flex-direction: column;
  }
  .card-front {
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
    .open-graph {
      flex-shrink: 0;
      width: 100%;
      height: 40%;
      overflow: hidden;
      position: relative;
      .index {
        position: absolute;
        right: 15px;
        top: 12px;
        font-size: 12px;
        padding: 1px 12px;
        background: rgba(216, 216, 216, 0.5);
        border-radius: 10px;
        color: #fff;
      }
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
      &:before {
        content: '';
        position: absolute;
        display: block;
        height: 50%;
        width: 100%;
        left: 0;
        bottom: 0;
        background: linear-gradient(
          to top,
          rgba(0, 0, 0, 0.7),
          rgba(0, 0, 0, 0)
        );
      }
      .open-graph-text {
        /* transition: 0.3s; */
        overflow: hidden;
        position: absolute;
        max-height: 48px;
        line-height: 24px;
        left: 10px;
        right: 10px;
        bottom: 10px;
        color: #fff;
        font-size: 20px;
        font-style: bold;
        color: #fff;
        display: block;
      }
    }
    .content {
      height: 60%;
      color: #222;
      flex: 1;
      display: flex;
      padding: 0 20px;
      flex-direction: column;
    }
    .content-title {
      padding-top: 15px;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.3);
      display: flex;
      justify-content: space-between;
    }
    .content-text-container {
      padding-top: 12px;
      flex: 1;
      font-size: 16px;
      overflow: hidden;
      line-height: 32px;
      text-align: justify;
    }
    .content-text {
      /* max 7 lines */
      max-height: 224px;
      overflow: hidden;
    }
    mark {
      background-color: rgba(255, 236, 118, 0.96);
    }
    @media screen and (max-height: 700px) {
      .content-text-container {
        padding-top: 10px;
      }
      /* max 5 lines */
      .content-text {
        line-height: 30px;
        max-height: 150px;
      }
    }
    .content-footer {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      height: 42px;
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: rgba(0, 0, 0, 0.4);
      .left {
        display: flex;
        align-items: center;
        /* a align left pure CSS icon */
        .icon {
          margin-right: 10px;
          height: 7px;
          width: 12px;
          border-top: solid 1px rgba(0, 0, 0, 0.4);
          border-bottom: solid 1px rgba(0, 0, 0, 0.4);
          position: relative;
          margin-bottom: 3px;
        }
        .icon:before {
          content: '';
          position: absolute;
          top: 2px;
          left: 0;
          height: 7px;
          width: 8px;
          border-top: solid 1px rgba(0, 0, 0, 0.4);
          border-bottom: solid 1px rgba(0, 0, 0, 0.4);
        }
        .estimate {
          font-size: 12px;
          color: rgba(0, 0, 0, 0.4);
        }
      }
      .right {
        position: relative;
        display: flex;
        justify-content: center;
        height: 100%;
        padding-top: 8px;
        img {
          width: 18px;
          height: 18px;
        }
        &:before {
          content: '';
          position: absolute;
          bottom: 8px;
          left: 0;
          width: 100%;
          display: block;
          border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        }
      }
    }
  }
  .card-back {
    display: flex;
    flex-direction: column;
    .header {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 84px;
      background: #ccc;
      padding: 14px;
      background-image: linear-gradient(to right, #48367b, #b179d1);
    }
    .kg-title {
      color: #fff;
      padding-left: 6px;
    }
    .kg-title-cn {
      font-size: 20px;
      line-height: 1.35;
    }
    .kg-title-en {
      font-size: 14px;
    }
    .kg-img {
      height: 62px;
      width: 62px;
      border-radius: 50%;
      background: #fff;
      overflow: hidden;
      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: 20px;
    }
    .description {
      flex-grow: 1;
      overflow: hidden;
      line-height: 1.5;
      max-height: 7.5em;
      font-size: 14px;
      position: relative;
      flex-shrink: 0;
      text-align: justify;
      &:after {
        content: '';
        position: absolute;
        display: block;
        right: 0;
        bottom: 0;
        height: 1.5em;
        width: 3em;
        background: linear-gradient(to left, white, rgba(255, 255, 255, 0));
      }
    }
    .properties {
      margin-top: 16px;
    }
    .property {
      font-size: 14px;
      line-height: 1.8;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .socials {
      margin-top: 16px;
      display: flex;
    }
    .social {
      width: 45px;
      margin-right: 20px;
    }
    .social-img {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .social-title {
      margin-top: 5px;
      text-align: center;
      font-size: 10px;
      color: darkblue;
    }
    .relatives-container {
      font-size: 14px;
      margin-top: auto;
      border-top: 2px solid rgba(0, 0, 0, 0.1);
    }
    .relatives-title {
      font-weight: bold;
      margin-top: 14px;
    }
    .relatives {
      font-size: 14px;
    }

    .relative {
      display: block;
      margin-top: 6px;
      width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  @media screen and (max-height: 700px) {
    .properties {
      max-height: 50px;
      overflow: hidden;
    }
    .relatives {
      max-height: 50px;
      overflow: hidden;
    }
    .socials {
      display: none !important;
    }
  }
`;
