import smiling from './icons/smiling.png';
import confused from './icons/confused.png';
import angry from './icons/angry.png';
import surprised from './icons/surprised.png';

export const DATA_URL = 'localhost:3001/data';
export const SERVER_DOMAIN = 'localhost:3001';

export const DEFAULT_PROPERTY = 'trending';

export const HEADER = {
  HEIGHT: 56,
};
export const FOOTER = {
  HEIGHT: 96,
};
export const DECK = {
  CARD_MARGIN: 100,
};

export const EMOTION = {
  smiling,
  confused,
  angry,
  surprised,
};
