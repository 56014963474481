import React, { useEffect, useState, useRef } from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import io from 'socket.io-client';
import Cards from './views/Cards';
import Comments from './views/Comments';
import Emotions, { useEmotions } from './views/Emotions';
import Exit from './views/Exit';
import { EMOTION, DATA_URL, SERVER_DOMAIN, DEFAULT_PROPERTY } from './constant';
import { getPic } from './helpers/genData';
import getMostEmo from './helpers/getMostEmo';
import shuffle from './helpers/shuffle';
import useGetterState from './helpers/useGetterState';
import Header from './Header';
import Footer from './Footer';
import mock from './mock';

function App() {
  const socketRef = useRef();
  function onEmit(data) {
    const socket = socketRef.current;
    if (socket) {
      socketRef.current.emit('event', data, console.log);
    } else {
      console.log('no socket');
    }
  }
  const [user, setUser] = useUser();
  const [emotions, addEmotion, setEmotions] = useEmotions(8);
  const [data, setData] = useData({
    url: `http://${DATA_URL}/${DEFAULT_PROPERTY}`,
    initState: [],
    processData: res =>
      res.json().then(({ content }) =>
        [
          {
            id: 'choose',
          },
          ...content.content,
        ].map(formatInitData),
      ),
    succeed(data) {
      if (data.length) {
        data[data.length - 1].active = true;
        callBackAfterSettingCardsPose(() => setData(data));
      }
    },
    fail(e) {
      const data = [
        {
          id: 'choose',
        },
        ...mock[DEFAULT_PROPERTY].content.content,
      ].map(formatInitData);
      data[data.length - 1].active = true;
      callBackAfterSettingCardsPose(() => setData(data));
      if (process.env.NODE_ENV === 'development') {
        console.log('get default data failed.', e);
      }
      console.log('Mock default data');
    },
    callback() {
      // console.log('你可以開始滑卡片了。');
    },
  });
  const read = useRef({});
  function addRead(card) {
    const { id, category } = card;
    if (!category || !id) return;
    if (read.current[category]) {
      read.current[category].push(id);
    } else {
      read.current[category] = [id];
    }
  }
  function getMostRead() {
    const reads = [];
    Object.entries(read.current).forEach(([cat, arr]) => {
      reads.push([cat, new Set(arr).size]);
    });
    reads.sort((a, b) => b[1] - a[1]);
    return reads.slice(0, 4);
  }
  const [currentId, getCurrentId] = useCurrentId(data, addRead);
  useEffect(() => {
    if (!currentId || currentId === 'choose') return;
    fetch(`http://${SERVER_DOMAIN}/news/${currentId}/watched`, {
      method: 'PUT',
    })
      .then(r => r.json())
      .then(data => {
        setEmotions(getMostEmo(data));
      })
      .catch(e => {
        if (process.env.NODE_ENV === 'development') {
          console.log('get emotions failed.', e);
        }
        // console.log('Mock emotions');
        // const emoSrc = Object.values(EMOTION)[
        //   Math.floor(Math.random() * Object.values(EMOTION).length)
        // ];
        // setEmotions([emoSrc, 5]);
      });
  }, [currentId]);
  const [style, setStyle] = useState('card');
  useEffect(() => {
    if (!user.id) return;
    const socket = io(`http://${SERVER_DOMAIN}`);
    socket.on('connect', () => {
      socketRef.current = socket;
      console.log('ws connected');
    });
    socket.on('event', function(data) {
      if (data.type === 'emotion') {
        // console.log(
        //   `${data.emo} for news ${data.newsId} from user ${data.userId}`,
        // );
        if (getCurrentId() === data.newsId) {
          addEmotion(EMOTION[data.emo]);
        }
      }
    });
    socket.on('disconnect', function() {
      socketRef.current = null;
      console.log('disconnected');
    });
    return () => {
      socket.close();
      socketRef.current = null;
    };
  }, [user.id]);
  function addFavorite(card) {
    if (!user.id) return console.log('no user id');
    const _card = { ...card };
    _card.active = false;
    fetch(`http://${SERVER_DOMAIN}/user/${user.id}/news`, {
      body: JSON.stringify(_card),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      credentials: 'include',
    })
      .then(r => r.json())
      .then(data => {
        setUser({ ...data, id: data._id });
        // console.log(data);
      })
      .catch(console.log);
  }
  function removeFavorite(card) {
    if (!user.id) return console.log('no user id');
    const _card = { ...card };
    _card.active = false;
    fetch(`http://${SERVER_DOMAIN}/user/${user.id}/news`, {
      body: JSON.stringify(_card),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
      credentials: 'include',
    })
      .then(r => r.json())
      .then(data => {
        setUser({ ...data, id: data._id });
        // console.log(data);
      })
      .catch(console.log);
  }
  const cache = useRef([]);
  const [showingFavorite, setShowingFavorite] = useState(false);
  function switchFavorite() {
    if (showingFavorite) {
      setShowingFavorite(false);
      const newData = cache.current.map((n, i) => ({
        ...n,
        active: i === cache.current.length - 1,
        originIndex: i,
      }));
      callBackAfterSettingCardsPose(() => setData(newData));
    } else {
      setShowingFavorite(true);
      const newData = user.news.map((n, i) => ({
        ...n,
        active: i === user.news.length - 1,
        originIndex: i,
      }));
      callBackAfterSettingCardsPose(() => {
        cache.current = data;
        setData(newData);
      });
    }
  }
  const [apisObject, setApisObject] = useData({
    url: `http://${DATA_URL}`,
    initState: {},
    processData: res => res.json().then(({ content }) => content),
    succeed(data) {
      delete data[DEFAULT_PROPERTY];
      setApisObject(data);
    },
    fail(e) {
      const data = mock.apiObject.content;
      delete data[DEFAULT_PROPERTY];
      setApisObject(data);
      if (process.env.NODE_ENV === 'development') {
        console.log('get api list failed.', e);
      }
      console.log('Mock api list.');
    },
    callback() {},
  });
  function chooseApis(apis) {
    Promise.all(
      apis.map(a =>
        fetch(`http://${DATA_URL}/${a.api}`)
          .then(r => r.json())
          .then(({ content }) =>
            content.content.map(c => ({ ...c, category: a.name })),
          )
          .catch(e => {
            if (process.env.NODE_ENV === 'development') {
              console.log(`get data ${a.api} failed.`, e);
            }
            console.log(`Mock data ${a.api}.`);
            return mock[a.api].content.content.map(c => ({
              ...c,
              category: a.name,
            }));
          }),
      ),
    )
      .then(function(values) {
        const newData = [
          {
            id: 'choose',
          },
          ...shuffle(values.reduce((acc, cur) => [...acc, ...cur], [])).slice(
            0,
            14,
          ),
        ].map(formatInitData);
        callBackAfterSettingCardsPose(() => setData(newData));
      })
      .catch(console.log);
  }
  const [cardsPose, setCardsPose] = useState('in');
  function callBackAfterSettingCardsPose(cb) {
    setCardsPose('out');
    setTimeout(() => {
      cb();
      setCardsPose('in');
    }, 500);
  }
  const [time, setTime] = useState(15);
  useEffect(() => {
    const timer = setInterval(() => {
      setTime(time - 1);
    }, 20000);
    if (time === 0) {
      setCardsPose('over');
      clearInterval(timer);
    }
    return () => clearInterval(timer);
  }, [time]);
  return (
    <Router>
      <Route
        render={({ location, history }) => (
          <Header
            onClickBus={() => {
              setCardsPose('in');
              setTime(15);
              history.goBack();
            }}
            location={location}
            time={time}
          />
        )}
      />
      <Cards
        onEmit={onEmit}
        user={user}
        setEmotions={setEmotions}
        cards={data}
        setCards={setData}
        style={style}
        setStyle={setStyle}
        addFavorite={addFavorite}
        removeFavorite={removeFavorite}
        apisObject={apisObject}
        chooseApis={chooseApis}
        cardsPose={cardsPose}
      />
      <Emotions emotions={emotions} />
      <Footer
        switchFavorite={switchFavorite}
        showingFavorite={showingFavorite}
        style={style}
        setStyle={setStyle}
        currentId={currentId}
        cards={data}
        onEmit={onEmit}
        user={user}
        addEmotion={addEmotion}
      />
      <Route render={props => <Comments {...props} cards={data} />} />
      {cardsPose === 'over' && <Redirect push to="exit" />}
      <Route
        path="/exit"
        render={props => (
          <Exit
            getDetail={getMostRead}
            goBack={() => {
              setCardsPose('in');
              setTime(15);
              props.history.goBack();
            }}
          />
        )}
      />
    </Router>
  );
}

function useUser() {
  const [user, setUser] = useState({ id: null, news: [] });
  useEffect(() => {
    fetch(`http://${SERVER_DOMAIN}/user`, { credentials: 'include' })
      .then(r => r.json())
      .then(data => {
        setUser({ ...data, id: data._id });
      })
      .catch(e => {
        console.log('get user fail', e);
      });
  }, []);
  return [user, setUser];
}
const useData = ({ url, initState, fail, succeed, processData, callback }) => {
  const [data, setData] = useState(initState);
  useEffect(() => {
    new Promise((res, rej) => {
      setTimeout(() => {
        rej('網路太慢所以吃 timeout');
      }, 5000);
      fetch(url)
        .then(processData)
        .then(res)
        .catch(rej);
    })
      .then(succeed)
      .catch(fail)
      .finally(callback);
  }, [url]);
  return [data, setData];
};

function useCurrentId(data, callback) {
  const [currentId, setCurrentId, getCurrentId] = useGetterState(null);
  useEffect(() => {
    if (!data.length) return setCurrentId(null);
    const newId = data[data.length - 1].id;
    if (newId === currentId) return;
    callback(data[data.length - 1]);
    setCurrentId(newId);
  }, [data]);
  return [currentId, getCurrentId];
}
function formatInitData(c, i) {
  return {
    ...c,
    thumbnails: c.thumbnails || getPic(),
    cover: false,
    currentKG: '',
    active: false,
    originIndex: i,
  };
}
export default App;
