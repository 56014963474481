import React, { useEffect, useState } from 'react';
import Comment from '../Comment';
import posed, { PoseGroup } from 'react-pose';
const C = posed.div({
  enter: {
    y: 0,
    opacity: 1,
    transition: {
      y: ({ from, to }) =>
        from > 0
          ? {
              from: -20,
              type: 'tween',
            }
          : {
              from: 0,
              type: 'tween',
            },
      opacity: ({ from }) => ({
        from,
        type: 'tween',
      }),
    },
  },
  exit: {
    y: 20,
    opacity: 0,
  },
});

function CardComment({ cardId, cards }) {
  const [comment, setComment] = useState([]);
  const [index, setIndex] = useState(-1);
  useEffect(() => {
    setIndex(-1);
    if (!cardId) return;
    // depends on cardId(currentId) because card status shouldn't affect comments animation
    const card = cards.find(c => c.id == cardId);
    if (!card) return;
    const messages = card.messages;
    if (!messages) return;
    setComment(messages);
    let timeout = setTimeout(() => {
      setIndex(0);
    }, 300);
    if (messages.length <= 1)
      return () => {
        clearTimeout(timeout);
      };
    let _index = 0;
    const interval = setInterval(() => {
      setIndex(-1);
      timeout = setTimeout(() => {
        _index += 1;
        setIndex(_index % messages.length);
      }, 300);
    }, 3000);
    return () => {
      clearTimeout(timeout);
      clearInterval(interval);
    };
  }, [cardId]);
  const currentComment = comment[index];
  return (
    <PoseGroup>
      {currentComment && (
        <C key={currentComment}>
          <Comment comment={currentComment} />
        </C>
      )}
    </PoseGroup>
  );
}

export default CardComment;
