import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import close from './icons/cancel.svg';
import menu from './icons/menu.svg';
import topBar from './icons/top-bar.png';
import bus from './icons/bus.png';

function Header({ className, location, time, onClickBus }) {
  return (
    <header className={className}>
      <img
        src={topBar}
        alt="top-bar"
        className="top-background"
        style={{ opacity: location.pathname !== '/comments' ? 1 : 0 }}
      />
      <div className="top-bar">
        <Link
          to="/"
          style={{
            pointerEvents: location.pathname === '/exit' ? 'none' : 'auto',
          }}
        >
          <div className="icon">
            <img src={close} alt="close" />
          </div>
        </Link>
        <div className="title">
          <div className="bus-container" onClick={onClickBus}>
            <img className="bus" src={bus} alt="bus" />
          </div>
          <div className="info">
            <div>688 公車</div>
            <div>{getHint(time)}</div>
          </div>
        </div>
      </div>
    </header>
  );
}

function getHint(min) {
  if (min >= 3)
    return (
      <>
        將在 <strong>{min}分鐘</strong> 到站
      </>
    );
  return <strong style={{ color: '#f8e71c' }}>即將到站！請留意</strong>;
}

export default styled(Header)`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;
  overflow: hidden;
  height: 56px;
  color: white;
  .top-background {
    transition: 2s;
    opacity: 1;
    position: absolute;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
  .top-bar {
    z-index: 2;
    font-size: 0.95em;
    display: flex;
    align-items: center;
    height: 56px;
    position: relative;
  }
  .title {
    position: absolute;
    display: flex;
    align-items: center;
    right: 12px;
    height: 100%;
  }
  .bus-container {
    margin: 5px 5px 0 0;
    width: 20px;
  }
  .bus {
    height: 100%;
    width: 100%;
  }
  .info {
    padding-left: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .time {
    margin: 0 5px;
    font-weight: bolder;
  }
  .icon {
    margin-left: 10px;
    height: 36px;
    width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      height: 16px;
      width: 16px;
    }
  }
  .drop-down {
    font-weight: bold;
  }
`;
