import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import CardComment from './components/CardComment';
import { EMOTION, FOOTER, SERVER_DOMAIN } from './constant';
import favoriteN from './icons/favorite-n.png';
import favorite from './icons/favorite.png';
import deck from './icons/deck.png';

function Footer({
  className,
  style,
  currentId,
  cards,
  setStyle,
  onEmit,
  user,
  showingFavorite,
  switchFavorite,
  addEmotion,
}) {
  function onClickEmo(emo) {
    if (!currentId) return console.log('no id!');
    if (!user.id) {
      return addEmotion(EMOTION[emo]);
    }
    fetch(`http://${SERVER_DOMAIN}/news/${currentId}/${emo}`, {
      method: 'PUT',
    })
      .then(r => r.json())
      .then(console.log)
      .catch(console.log);
    onEmit({ type: 'emotion', emo, newsId: currentId, userId: user.id });
  }
  return (
    <footer className={className}>
      {style === 'card' && (
        <Link to={{ pathname: `/comments`, state: { currentId } }}>
          <div className="comment-outer">
            <CardComment cards={cards} cardId={currentId} />
          </div>
        </Link>
      )}
      <div className="buttons-container">
        <div className="button-container" onClick={switchFavorite}>
          <img
            className="button left"
            src={showingFavorite ? favorite : favoriteN}
            alt="favorite"
          />
        </div>
        <div
          className="emotions-container"
          style={{
            transform: `translate(${
              style === 'deck' ? `0px,${FOOTER.HEIGHT}px` : '0, 0'
            })`,
          }}
        >
          <div className="emotions">
            {Object.entries(EMOTION).map(([emoKey, imgSrc]) => (
              <div
                key={emoKey}
                onClick={() => {
                  onClickEmo(emoKey);
                }}
                className="emo icon"
              >
                <img src={imgSrc} alt="" />
              </div>
            ))}
          </div>
        </div>
        <div
          className="button-container"
          onClick={() => {
            setStyle(style === 'card' ? 'deck' : 'card');
          }}
        >
          <img className="button right" src={deck} alt="deck" />
        </div>
      </div>
    </footer>
  );
}

export default styled(Footer)`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: ${FOOTER.HEIGHT}px;
  bottom: 0;
  left: 0;
  right: 0;
  touch-action: manipulation;
  .comment-outer {
    display: flex;
    justify-content: center;
    height: 36px;
  }
  .buttons-container {
    display: flex;
    align-items: center;
    height: 60px;
    padding-bottom: 10px;
  }
  .button-container {
    height: 100%;
    padding: 10px;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    .button {
      width: 40px;
      height: 40px;
      object-fit: cover;
    }
    .button.right {
      width: 60px;
      height: 60px;
    }
    .button.left {
      width: 35px;
      height: 35px;
      object-fit: contain;
    }
  }
  .emotions-container {
    transition: all 0.5s;
    flex: 1;
    height: 100%;
    display: flex;
    justify-content: center;
    position: relative;
  }
  .emotions {
    position: absolute;
    box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.3);
    background: #fff;
    border-radius: 30px;
    display: flex;
    align-items: center;
    line-height: 0;
    justify-content: space-evenly;
    padding: 0 20px;
    height: 100%;
    transition: 0.2s;
    &:active .emo {
      margin: 0 6px;
    }
  }
  .emo {
    height: 40px;
    width: 40px;
    transition: 0.1s;
    margin: 0 8px;
    &:active {
      width: 72px;
      height: 72px;
    }
    img {
      object-fit: contain;
      height: 100%;
      width: 100%;
    }
  }
`;
