import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import posed, { PoseGroup } from 'react-pose';
import { HEADER } from '../../constant';
import genIDer from '../../helpers/genID';
import shuffle from '../../helpers/shuffle';
import yahoo from '../../icons/yahoo.png';
import ptt from '../../icons/ptt.png';
import background from '../../icons/background.png';

const genID = genIDer();

function Comments({ className, location, cards }) {
  const bg = useRef(null);
  const cover = useRef(null);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    const b = bg.current;
    const c = cover.current;
    if (!b || !c) return;
    let timer;
    if (location.pathname.startsWith('/comments')) {
      c.style.pointerEvents = 'auto';
      c.style.WebkitClipPath = 'ellipse(800% 150% at 80% -10%)';
      c.style.boxShadow = '0 0 0 blue';
      timer = setTimeout(() => {
        b.style.transitionDuration = '1.5s';
        b.style.opacity = 0.1;
      }, 1500);
    } else {
      c.style.pointerEvents = 'none';
      c.style.WebkitClipPath = 'ellipse(0 0 at 80% -10%)';
      c.style.boxShadow = '0 0 10px black';
      timer = setTimeout(() => {
        b.style.transitionDuration = '0s';
        b.style.opacity = 0;
      }, 1500);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [location.pathname]);
  // todo: use state instead of pathname to open comments page
  const [card, setCard] = useState(null);
  useEffect(() => {
    let timer;
    if (location.pathname.startsWith('/comments')) {
      setCard(cards.find(c => c.id == location.state.currentId));
      timer = setTimeout(() => {
        setOpen(true);
      }, 1000);
    } else {
      timer = setTimeout(() => {
        setCard(null);
        setOpen(false);
      }, 1000);
    }
    return () => clearTimeout(timer);
  }, [location.pathname]);

  const [commentStatus, setCommentStatus] = useState({
    status: [],
    current: [],
  });
  function onAnimationEnd(index) {
    setCommentStatus(commentStatus => {
      const { status, current } = commentStatus;
      const nextIndex = commentStatus.status.findIndex(s => !s.showed);
      const newCurrent = [...current];
      if (nextIndex < 0) {
        newCurrent[index] = { index: 0, id: genID() };
        const newStatus = status.map(s => ({ ...s, showed: false }));
        newStatus[0] = { ...newStatus[0], showed: true };
        return {
          status: newStatus,
          current: newCurrent,
        };
      } else {
        const newStatus = [...status];
        newStatus[nextIndex] = { ...newStatus[nextIndex], showed: true };
        const newCurrent = [...current];
        newCurrent[index] = {
          ...newCurrent[index],
          index: nextIndex,
          id: genID(),
        };
        return {
          status: newStatus,
          current: newCurrent,
        };
      }
    });
  }
  useEffect(() => {
    if (!card || !card.messages.length) return;
    const comments = card.messages;
    const length = comments.length;
    const status = comments.map((c, i) => ({
      comment_type: c.comment_type,
      text: c.text,
      showed: i < 8,
    }));
    const current = shuffle(
      Array.from({ length: length > 8 ? 8 : length }, (_, i) => ({
        index: i,
        id: genID(),
      })),
    );
    setCommentStatus({
      status,
      current,
    });
  }, [card]);
  function renderComment({ comment_type, name, text }) {
    if (comment_type === 'yahoo') return <Yahoo name={name} text={text} />;
    if (comment_type === 'ptt') return <Ptt name={name} text={text} />;
  }
  return (
    <div className={className}>
      <div ref={cover} className="cover">
        <div ref={bg} className="background">
          {card && card.thumbnails && (
            <img src={card.thumbnails} alt={card.title} />
          )}
        </div>
        {card && (
          <div className="comments-container">
            <div className="comments-title">{card.title}</div>
            <Items className="comments" pose={open ? 'open' : 'closed'}>
              {commentStatus.current.map((c, i) => {
                const comment = card.messages[c.index];
                if (!comment) return null;
                return (
                  <Item key={c.id} className="comment-anchor">
                    <div
                      onAnimationEnd={() => onAnimationEnd(i)}
                      className="comment-container"
                      style={{
                        animationDuration: `${comment.duration}s`,
                      }}
                    >
                      {renderComment(comment)}
                    </div>
                  </Item>
                );
              })}
            </Items>
          </div>
        )}
      </div>
    </div>
  );
}

const Ptt = ({ text, name }) => (
  <div className="comment-inner ptt">
    <div className="comment-icon">
      <img src={ptt} alt="ptt" />
    </div>
    <div className="comment">{`${name}：${text}`}</div>
  </div>
);
const Yahoo = ({ text, name }) => (
  <div className="comment-inner yahoo">
    <div className="comment-icon">
      <img src={yahoo} alt="yahoo" />
    </div>
    <div className="comment">{`${name}：${text}`}</div>
  </div>
);

const Items = posed.div({
  open: {
    staggerChildren: 100,
  },
  closed: {},
});
const Item = posed.div({
  open: {
    opacity: 1,
    y: 0,
  },
  closed: {
    opacity: 0,
    y: 10,
  },
});

export default styled(Comments)`
  .background {
    height: 100%;
    width: 100%;
    opacity: 0;
    position: absolute;
    background: #fff;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .comments-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    align-items: center;
    height: 100%;
    width: 100%;
    padding-top: ${HEADER.HEIGHT}px;
    padding-bottom: 20px;
  }
  .comments-title {
    overflow: hidden;
    max-height: 48px;
    line-height: 24px;
    font-style: bold;
    display: flex;
    justify-content: center;
    /* margin-top: 10px; */
    margin: 20px;
    overflow: hidden;
    font-size: 20px;
    line-height: 24px;
    color: #fff;
  }
  .comments {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
  }
  .comment-anchor {
    position: relative;
    left: 100%;
  }
  .comment-container {
    animation: 10s fly linear;
    height: 46px;
    line-height: 23px
    display: flex;
    align-items: center;
  }
  .comment-inner {
    max-height: 100%;
    display: flex;
    padding: 0 8px;
    border-radius: 12px;
    color: #fff;
    &.yahoo {
      background-color: rgba(255, 255, 255, 0.29);
    }
    &.ptt {
      background-color: rgba(0, 0, 0, 0.29);
    }
  }
  .comment-icon {
    margin: 3.5px 5px 0 0;
    border-radius: 50%;
    height: 16px;
    width: 16px;
    overflow:hidden;
    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }
  .comment {
    max-height: 100%;
    overflow: hidden;
    max-width: 250px;
  }
  .cover {
    width: 100%;
    height: 100%;
    position: fixed;
    background-image: url(${background});
    background-size: cover;
    transition: 1.5s;
    box-shadow: 0 0 black;
    -webkit-clip-path: ellipse(0 0 at 80% -10%);
  }
  @keyframes fly {
    0% {
      opacity: 0;
      transform: translateX(-60%);
    }
    10% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: translateX(calc(${() => -window.innerWidth}px - 40%));
    }
  }
`;
