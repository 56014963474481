import React from 'react';
import styled from 'styled-components';
import yahoo from '../../icons/yahoo.png';
import ptt from '../../icons/ptt.png';

function Comment({ className, comment, style }) {
  if (!comment) return null;
  if (comment.comment_type === 'yahoo')
    return <Yahoo name={comment.name} text={comment.text} />;
  if (comment.comment_type === 'ptt')
    return <Ptt name={comment.name} text={comment.text} />;
  return null;
}

const Ptt = ({ text, name }) => (
  <StyledComment>
    <div className="comment-icon">
      <img src={ptt} alt="ptt" />
    </div>
    <div className="comment">{`${name}：${text}`}</div>
  </StyledComment>
);
const Yahoo = ({ text, name }) => (
  <StyledComment>
    <div className="comment-icon">
      <img src={yahoo} alt="yahoo" />
    </div>
    <div className="comment">{`${name}：${text}`}</div>
  </StyledComment>
);
const StyledComment = styled.div`
  display: flex;
  background: rgba(0, 0, 0, 0.1);
  padding: 2px 8px;
  border-radius: 12px;
  align-items: center;
  color: rgba(0, 0, 0, 0.5);
  .comment-icon {
    margin-right: 5px;
    border-radius: 50%;
    height: 16px;
    width: 16px;
    img {
      height: 100%;
      width: 100%;
    }
  }
  .comment {
    font-size: 0.9em;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 300px;
  }
`;

export default Comment;
